export const liquidityAbi = [
	{
		"constant": true,
		"inputs": [],
		"name": "getReserves",
		"outputs": [
			{
				"internalType": "uint112",
				"name": "_reserve0",
				"type": "uint112"
			},
			{
				"internalType": "uint112",
				"name": "_reserve1",
				"type": "uint112"
			},
			{
				"internalType": "uint32",
				"name": "_blockTimestampLast",
				"type": "uint32"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
]