<template>
    <div :class="typeClass">
        <div class="img">
            <div class="attention"></div>
        </div>
        <div class="text">
            <span class="title"></span>
            {{notification.message}}
        </div>
        <button @click="closeNotif()">   <div class="closeImg"></div></button>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import MultiLang from "@/core/multilang"

export default {
    data() {
        return {
            timeout: null,
            lang: new MultiLang(this),
        }
    },
    props: ['notification'],
    computed: {
        typeClass() {
             return `block ${this.notification.typeClass} flexInlineBetween`
        }
    },

    created() {
        const showTime = this?.notification.showTime || 3000
        this.timeout = setTimeout(() => {
            this.remove_notification(this.notification);
        }, showTime);
    },
    methods: {
        ...mapMutations(['remove_notification']),
        closeNotif(){
            this.remove_notification(this.notification);
    }
    },

    beforeDestroy() {
        clearTimeout(this.timeout)
    },
    
}
</script>