<template>
    <div v-if="showWalletOptions || showConnectToWalletModal" class="modalWindow">
        <div class="modalWrapper">
            <div class="modalHeader flexInlineBetween">
                <div class="leftPart flexInline">
                    <div class="title">
                        {{translatesGet('SELECT_WALLET')}}
                        <!-- {{lang.get("CONNECT_TO_WALLET")}} -->
                        </div>
                    </div>
                    
                <!-- <div class="rightPart">
                    <div class="close ">
                        <svg  @click="$emit('closeShowWalletOptions')" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M20.9161 19.9957L39.8112 1.10069C40.063 0.848805 40.063 0.440497 39.8112 0.18861C39.5593 -0.0631956 39.151 -0.0631956 38.8991 0.18861L20.004 19.0836L1.10899 0.18861C0.852754 -0.0588416 0.444447 -0.0517462 0.196914 0.204494C-0.0444905 0.454446 -0.0444905 0.85074 0.196914 1.10069L19.092 19.9957L0.196914 38.8908C-0.0593264 39.1383 -0.0664218 39.5466 0.181111 39.8028C0.428643 40.0591 0.836951 40.0662 1.09319 39.8187C1.09859 39.8135 1.10383 39.8082 1.10899 39.8028L20.004 20.9078L38.8991 39.8028C39.1553 40.0504 39.5636 40.0433 39.8112 39.787C40.0526 39.5371 40.0526 39.1407 39.8112 38.8908L20.9161 19.9957Z"
                                    fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="40" height="40" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                </div> -->
                <!-- <button @click="$emit('closeShowWalletOptions'), closeNoWalletWindow()" >
                    <div class="close-icon"></div>
                </button> -->
            </div>
             <div class="lineLightGrey h"></div>
            <div class="content">
                <div class="selectWalletWrapper ">
                    <button @click="$emit('selectedOption', 'metamask'), wallet = 'metamask'" class="blockWithBorder  " :class="{active: isMetamask}">
                       <div class="walletIcon walletMetaMask"></div> <span>Metamask</span>
                    </button>
                    <button v-if="mobileDevice" @click="$emit('selectedOption', 'trustwallet'), wallet = 'metamask'" class="blockWithBorder  " :class="{active: isTrustWallet}">
                       <div class="walletIcon walletTrust"></div> <span>Trust Wallet</span>
                    </button>
                    <button v-else @click="$emit('selectedOption', 'binance'), wallet = 'binance'" class="blockWithBorder " :class="{active: isBinance}">
                       <div class="walletIcon walletBinance"></div> <span>Binance Chain</span>
                    </button>
                </div>
                <!-- <a @click="$emit('showHelp')" class="question-wrapper">
                    <span class="icon icon-question"></span>
                    <span>{{lang.get("HOW_TO_CONNECT")}}</span>
                </a> -->
                <div class="subtext">{{translatesGet('SELECT_WALLET_DESCR')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import MultiLang from '@/core/multilang';
import {mapState} from 'vuex';


export default {
    props: ['showWalletOptions'],
    data: function () {
        return {
            lang: new MultiLang(this),
            wallet: "",
            // showWalletOptions: true

        }
    },
    mounted() {
        this.lang.init()
    },
    computed: {
        ...mapState(['showConnectToWalletModal']),
        isMetamask() {
            const wallet = this.wallet || localStorage.getItem('selectedWallet');
            return wallet === 'metamask';
        },
        isTrustWallet() {
            const wallet = this.wallet || localStorage.getItem('selectedWallet');
            return wallet === 'trustwallet';
        },
       
        isBinance() {
            const wallet = this.wallet || localStorage.getItem('selectedWallet');
            return wallet === 'binance';
        },
        mobileDevice(){
           return (this?.$device?.ios || this?.$device?.android) ? true : false
        }
        
    },
    methods: {
        closeNoWalletWindow() {
            this.$store.commit("changeConnectToWallet", false);
        },
        translatesGet(key) {
      try {
        const translations = JSON.parse(window.localStorage.getItem('translations'))
        const res = translations.lang.find(el => el.hasOwnProperty(`${key}`));
        return res[key]
        
      } catch (ex) {
        // console.log(ex);
        return this.lang.get(key)
      }
    },
    }

}
</script>