import _ from 'lodash';

export default {
    data() {
        return{
            page: 1,
            plansPage: 1,

            pageSize: 5,
            plansPageSize: 6,

            pageCount: 0,
            plansPageCount: 0,

            allTransactions:[],
            allPlans:[],

            transactions: [],
            purchasedPlans: [],

        }
    },
    methods: {
        setupPagination(allTransactions) {
            //makes few arrays by chunking it
            
            this.allTransactions = _.chunk(allTransactions, this.pageSize);
            this.pageCount = _.size(this.allTransactions)
            this.transactions = this.allTransactions[this.page - 1] || this.allTransactions
        },

        showMoreTransactions() {
            //when button ShowMore clicked it increases page, so we load another part of transactions
            this.page++;
            if(this.page <= this.pageCount){
                this.transactions = this.transactions.concat(this.allTransactions[this.page - 1])
            }else {
                return;
            }
            
        },

        hideTransactions(){
            //when hide button is clicked it resets the page to initial, hiding the rest arrays 
            this.page = 1;
            this.transactions = this.allTransactions[this.page - 1] || this.allTransactions
        },

        setupPlansPagination(allPlans) {
            //makes few arrays by chunking it
            
            this.allPlans = _.chunk(allPlans, this.plansPageSize);
            this.plansPageCount = _.size(this.allPlans)
            this.purchasedPlans = this.allPlans[this.plansPage - 1] || this.allPlans
        },

        showMorePlans() {
            //when button ShowMore clicked it increases page, so we load another part of transactions
            this.plansPage++;
            if(this.plansPage <= this.plansPageCount){
                this.purchasedPlans = this.purchasedPlans.concat(this.allPlans[this.plansPage - 1])
            }else {
                return;
            }  
        },

        hidePlans(){
            //when hide button is clicked it resets the page to initial, hiding the rest arrays 
            this.plansPage = 1;
            this.purchasedPlans = this.allPlans[this.plansPage - 1] || this.allPlans
        }
    }
}