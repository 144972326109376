<template>
  <aside class="sidebar" v-bind:class="{ active: openSide }">
    <div class="darkBgBlock aboutMatrix">
      <div class="flexInlineBetween"></div>
      <div class="titleMatrixBlock flexInline" style="margin-bottom: 8px">
        <span
          >{{ translatesGet("RST_PRICE") }}
          </span
        >
      </div>
      <div class="countMatrix flexInline"><span class="count">{{ rstPriceFromContract > 0 ? rstPriceFromContract.toFixed(2) : "0.00" }}</span>BUSD</div>
      <div class="infoTotalAmount">{{ rstToUsdValue(rstTotalAmountWithRef > 0 ? rstTotalAmountWithRef : 0) }}$</div>
    </div>

    <div class="darkBgBlock aboutMatrix">
      <div class="matrixBlock">
        <div class="titleMatrixBlock flexInline">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.8242 9.57031H23.5352C24.3442 9.57031 25 8.9145 25 8.10547V4.44336C25 4.03887 24.6721 3.71094 24.2676 3.71094H22.0215L10.3027 2.24609H5.9082V14.6973C5.9082 15.1018 6.23613 15.4297 6.64062 15.4297H2.97852V22.7539H19.0918V15.4297H20.5566V16.1621C20.5566 16.5723 20.8789 16.8945 21.2891 16.8945H24.2676C24.6721 16.8945 25 16.5666 25 16.1621V12.5C25 11.691 24.3442 11.0352 23.5352 11.0352H19.8242C19.414 11.0352 19.0918 10.7129 19.0918 10.3027C19.0918 9.89253 19.414 9.57031 19.8242 9.57031Z"
              fill="url(#paint0_linear_1182:1204)"
            />
            <path
              d="M21.2891 13.9648H0.732422C0.322217 13.9648 0 14.2871 0 14.6973V23.4863C0 23.8965 0.322217 24.2188 0.732422 24.2188H21.2891C21.6993 24.2188 22.0215 23.8965 22.0215 23.4863V14.6973C22.0215 14.2871 21.6993 13.9648 21.2891 13.9648ZM6.64062 19.8242H5.17578C4.76558 19.8242 4.44336 19.502 4.44336 19.0918C4.44336 18.6816 4.76558 18.3594 5.17578 18.3594H6.64062C7.05083 18.3594 7.37305 18.6816 7.37305 19.0918C7.37305 19.502 7.05083 19.8242 6.64062 19.8242ZM11.0352 21.2891C9.81929 21.2891 8.83789 20.3077 8.83789 19.0918C8.83789 17.8759 9.81929 16.8945 11.0352 16.8945C12.251 16.8945 13.2324 17.8759 13.2324 19.0918C13.2324 20.3077 12.251 21.2891 11.0352 21.2891ZM16.8945 19.8242H15.4297C15.0195 19.8242 14.6973 19.502 14.6973 19.0918C14.6973 18.6816 15.0195 18.3594 15.4297 18.3594H16.8945C17.3047 18.3594 17.627 18.6816 17.627 19.0918C17.627 19.502 17.3047 19.8242 16.8945 19.8242ZM19.8242 8.10547C18.6126 8.10547 17.627 9.09111 17.627 10.3027C17.627 11.5144 18.6126 12.5 19.8242 12.5H25V8.10547H19.8242ZM7.37305 3.71094H22.0215V1.51367C22.0215 1.10347 21.6993 0.78125 21.2891 0.78125H7.37305C6.52432 0.78125 5.9082 1.48081 5.9082 2.24609C5.9082 3.05176 6.56738 3.71094 7.37305 3.71094Z"
              fill="url(#paint1_linear_1182:1204)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1182:1204"
                x1="13.9893"
                y1="22.7539"
                x2="13.9893"
                y2="2.24609"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00B59C" />
                <stop offset="1" stop-color="#9CFFAC" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1182:1204"
                x1="12.5"
                y1="24.2188"
                x2="12.5"
                y2="0.78125"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#C3FFE8" />
                <stop offset="0.9973" stop-color="#F0FFF4" />
              </linearGradient>
            </defs>
          </svg>
          <span>{{ translatesGet("TOTAL_BALANCE") }}</span>
        </div>
        <div class="countMatrix flexInline">
          <span class="count">{{ rstTotalAmount > 0 ? rstTotalAmount : "0.00" }}</span
          >RST
        </div>
        <div class="flexInlineBetween details">
          <span class="count"
            >{{
              rstToUsdValue(rstTotalAmount) > 0 ? rstToUsdValue(rstTotalAmount) : "0.00"
            }}
            $</span
          >
        </div>
      </div>
      <div class="lineLightGrey h"></div>
      <div class="matrixBlock">
        <div class="titleMatrixBlock flexInline">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.036 6.18931C23.5375 5.45117 22.9653 4.75806 22.3366 4.12935C21.7072 3.49995 21.014 2.92773 20.2759 2.4292C19.9412 2.20176 19.4849 2.28975 19.2596 2.62588C19.0328 2.9606 19.1208 3.41626 19.4562 3.64224C19.9142 3.95186 20.3438 4.30322 20.7577 4.67183L19.2295 6.19995C18.9453 6.48413 18.9437 6.94307 19.2241 7.22959C17.5004 5.50894 15.1224 4.44336 12.5004 4.44336C9.87831 4.44336 7.50038 5.50894 5.7767 7.22969C6.05702 6.94316 6.05541 6.48418 5.77123 6.20005L4.2431 4.67188C4.65697 4.30337 5.08641 3.95195 5.54452 3.64229C5.87997 3.41626 5.96796 2.96064 5.7412 2.62593C5.51517 2.28975 5.05814 2.20176 4.72484 2.42925C3.98671 2.92773 3.29359 3.49995 2.66488 4.12866C2.03549 4.75806 1.46327 5.45117 0.964735 6.18931C0.737977 6.52402 0.825965 6.97969 1.16141 7.20566C1.49423 7.43198 1.9517 7.34526 2.17777 7.00898C2.48739 6.55098 2.83876 6.12144 3.20736 5.70757L4.73549 7.23569C5.01967 7.51982 5.4786 7.52148 5.76517 7.24111C4.04447 8.96484 2.97889 11.3428 2.97889 13.9648C2.97889 19.2148 7.25038 23.4863 12.5004 23.4863C17.7504 23.4863 22.0219 19.2148 22.0219 13.9648C22.0219 11.3428 20.9563 8.96484 19.2355 7.24116C19.5221 7.52148 19.981 7.51987 20.2652 7.23574L21.7933 5.70762C22.1619 6.12148 22.5133 6.55093 22.8229 7.00903C23.0493 7.3457 23.5069 7.43179 23.8393 7.20571C24.1748 6.97964 24.2628 6.52402 24.036 6.18931ZM14.6976 2.24609C14.6976 1.03447 13.712 0 12.5004 0C11.2888 0 10.3031 1.03447 10.3031 2.24609C10.3031 3.45771 11.2888 4.44336 12.5004 4.44336C13.712 4.44336 14.6976 3.45771 14.6976 2.24609ZM12.5004 2.97852C12.0963 2.97852 11.768 2.6502 11.768 2.24609C11.768 1.84199 12.0963 1.51367 12.5004 1.51367C12.9045 1.51367 13.2328 1.84199 13.2328 2.24609C13.2328 2.6502 12.9045 2.97852 12.5004 2.97852Z"
              fill="url(#paint0_linear_1182:1186)"
            />
            <path
              d="M12.676 13.2471C11.8557 13.0566 11.7678 12.9833 11.7678 12.5C11.7678 11.8555 12.7053 11.5478 13.0862 12.0605C13.3352 12.3828 13.7893 12.456 14.1116 12.207C14.4339 11.958 14.5071 11.5039 14.2581 11.1816C13.9944 10.8301 13.6282 10.5957 13.2327 10.4492V9.57031C13.2327 9.16011 12.9105 8.83789 12.5003 8.83789C12.0901 8.83789 11.7679 9.16011 11.7679 9.57031V10.4345C10.9182 10.7421 10.303 11.5478 10.303 12.5C10.303 14.1845 11.5774 14.4922 12.3245 14.6826C13.1448 14.873 13.2327 14.9463 13.2327 15.4297C13.2327 15.8398 12.9105 16.1621 12.5003 16.1621C12.266 16.1621 12.0608 16.0596 11.9143 15.8691C11.68 15.5468 11.2112 15.4883 10.889 15.7227C10.5667 15.9717 10.4934 16.4258 10.7424 16.748C11.0061 17.0996 11.3723 17.3339 11.7678 17.4804V18.3594C11.7678 18.7695 12.09 19.0918 12.5002 19.0918C12.9104 19.0918 13.2327 18.7695 13.2327 18.3594V17.4951C14.0823 17.1875 14.6975 16.3818 14.6975 15.4297C14.6975 13.7451 13.4231 13.4375 12.676 13.2471V13.2471ZM12.5002 2.97852C6.43579 2.97852 1.51392 7.90039 1.51392 13.9648C1.51392 20.0293 6.43579 25 12.5002 25C18.5647 25 23.4866 20.0293 23.4866 13.9648C23.4866 7.90039 18.5647 2.97852 12.5002 2.97852ZM12.5002 22.0215C8.06172 22.0215 4.4436 18.4033 4.4436 13.9648C4.4436 9.52632 8.06172 5.9082 12.5002 5.9082C16.9388 5.9082 20.5569 9.52632 20.5569 13.9648C20.5569 18.4033 16.9388 22.0215 12.5002 22.0215Z"
              fill="url(#paint1_linear_1182:1186)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1182:1186"
                x1="12.5004"
                y1="7.41689"
                x2="12.5004"
                y2="0"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#ADDCFF" />
                <stop offset="0.5028" stop-color="#EAF6FF" />
                <stop offset="1" stop-color="#EAF6FF" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1182:1186"
                x1="12.5002"
                y1="25"
                x2="12.5002"
                y2="2.97852"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#5558FF" />
                <stop offset="1" stop-color="#00C0FF" />
              </linearGradient>
            </defs>
          </svg>
          <span>{{ translatesGet("LOCKED") }}</span>
        </div>
        <div class="countMatrix flexInline">
          <span class="count">{{ rstTokensLocked > 0 ? rstTokensLocked : "0.00" }}</span
          >RST
        </div>
        <div class="flexInlineBetween details">
          <span class="count"
            >{{
              rstToUsdValue(rstTokensLocked) > 0
                ? rstToUsdValue(rstTokensLocked)
                : "0.00"
            }}$</span
          >
        </div>
      </div>
      <div class="lineLightGrey h"></div>
      <div class="matrixBlock">
        <div class="titleMatrixBlock flexInline">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.0215 14.0137C22.0215 12.3328 21.5823 10.6863 20.7512 9.2522C20.6203 9.02617 20.3785 8.88672 20.1174 8.88672H19.0918V7.46553C19.0918 7.25669 19.0024 7.05781 18.8465 6.91909C17.4868 5.70674 15.8403 4.91206 14.085 4.61953C13.6274 4.54839 13.2324 4.89639 13.2324 5.34194V7.42187H11.7676V5.34189C11.7676 4.89682 11.3734 4.54848 10.915 4.61948C9.15977 4.91201 7.51323 5.70669 6.15352 6.91904C5.99761 7.05781 5.9082 7.25664 5.9082 7.46548V8.88672H4.88252C4.62144 8.88672 4.37969 9.02617 4.24878 9.2522C3.41768 10.6863 2.97852 12.3328 2.97852 14.0137C2.97852 16.2245 3.74028 18.3496 5.19512 20.112C4.80171 20.5869 4.44336 21.1627 4.44336 22.0703C4.44336 23.6861 5.75728 25 7.37305 25C8.20273 25 9.00381 24.6395 9.57817 24.0044L10.2305 23.2627C11.7153 23.6246 13.2846 23.6246 14.7695 23.2627L15.4282 24.0123C15.9962 24.6395 16.7973 25 17.627 25C19.2427 25 20.5566 23.6861 20.5566 22.0703C20.5566 21.1627 20.1869 20.574 19.8049 20.112C21.2369 18.4025 22.0215 16.2531 22.0215 14.0137V14.0137Z"
              fill="url(#paint0_linear_1182:1196)"
            />
            <path
              d="M8.10547 11.8164C7.70098 11.8164 7.37305 12.1443 7.37305 12.5488C7.37305 12.9533 7.70098 13.2812 8.10547 13.2812C8.50996 13.2812 8.83789 12.9533 8.83789 12.5488C8.83789 12.1443 8.50996 11.8164 8.10547 11.8164ZM16.8945 11.8164C16.49 11.8164 16.1621 12.1443 16.1621 12.5488C16.1621 12.9533 16.49 13.2812 16.8945 13.2812C17.299 13.2812 17.627 12.9533 17.627 12.5488C17.627 12.1443 17.299 11.8164 16.8945 11.8164ZM12.5 13.2812C10.4808 13.2812 8.83789 14.5952 8.83789 16.2109C8.83789 17.8267 10.4808 19.1406 12.5 19.1406C14.5192 19.1406 16.1621 17.8267 16.1621 16.2109C16.1621 14.5952 14.5192 13.2812 12.5 13.2812ZM11.0352 8.88672H13.9648C14.3697 8.88672 14.6973 8.55913 14.6973 8.1543V0.732422C14.6973 0.327588 14.3697 0 13.9648 0H11.0352C10.6303 0 10.3027 0.327588 10.3027 0.732422V8.1543C10.3027 8.55913 10.6303 8.88672 11.0352 8.88672ZM7.37305 5.22461C7.37305 4.81978 7.04546 4.49219 6.64062 4.49219C3.81392 4.49219 1.51367 6.79243 1.51367 9.61914C1.51367 10.024 1.84126 10.3516 2.24609 10.3516H6.64062C7.04546 10.3516 7.37305 10.024 7.37305 9.61914V5.22461ZM18.3594 4.49219C17.9545 4.49219 17.627 4.81978 17.627 5.22461V9.61914C17.627 10.024 17.9545 10.3516 18.3594 10.3516H22.7539C23.1587 10.3516 23.4863 10.024 23.4863 9.61914C23.4863 6.79243 21.1861 4.49219 18.3594 4.49219Z"
              fill="url(#paint1_linear_1182:1196)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1182:1196"
                x1="12.5"
                y1="25"
                x2="12.5"
                y2="4.61025"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FD5900" />
                <stop offset="1" stop-color="#FFDE00" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1182:1196"
                x1="12.5"
                y1="19.1406"
                x2="12.5"
                y2="0"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFE59A" />
                <stop offset="1" stop-color="#FFFFD5" />
              </linearGradient>
            </defs>
          </svg>
          <span>{{ translatesGet("AVAILABLE") }}</span>
        </div>
        <div class="countMatrix flexInline">
          <span class="count">{{
            rstTokensAvailable > 0 ? rstTokensAvailable : "0.00"
          }}</span
          >RST
        </div>
        <div class="flexInlineBetween details">
          <span class="count"
            >{{
              rstToUsdValue(rstTokensAvailable) > 0
                ? rstToUsdValue(rstTokensAvailable)
                : "0.00"
            }}
            $</span
          >
        </div>
      </div>
      <button :disabled="!currentAddress || currentAddress === '0x0000000000000000000000000000000000000000' || Number(rstTokensAvailable) === 0" @click="claimTokens()" class="btn btnWithdraw">
        {{ translatesGet("WITHDRAW") }}
      </button>
    </div>
    <div class="darkBgBlock aboutMatrix">
      <div class="matrixBlock">
        <div class="titleMatrixBlock flexInline">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.7041 8.83789L14.0924 7.92793L15.0635 7.37305C15.1075 7.27051 15.1514 7.16797 15.1807 7.05083C15.3711 6.46484 15.7959 5.98145 16.3672 5.73242C17.3193 5.29297 17.627 4.17969 17.627 3.71094C17.627 2.49507 16.6456 1.51367 15.4297 1.51367H15.4151C14.7267 0.605469 13.6573 0 12.5 0C11.3428 0 10.2734 0.605469 9.58501 1.51367C8.38379 1.46968 7.37305 2.49507 7.37305 3.71094C7.37305 4.17969 7.68071 5.29302 8.64746 5.71777C9.2041 5.98145 9.62886 6.46484 9.81934 7.03618C9.84868 7.15332 9.89258 7.27051 9.93657 7.37305L10.9076 7.92793L8.2959 8.83789C4.98535 11.6503 2.97852 17.3926 2.97852 19.8242C2.97852 23.1348 6.3623 25 12.5 25C18.6377 25 22.0215 23.1348 22.0215 19.8242C22.0215 17.3926 20.0146 11.6503 16.7041 8.83789Z"
              fill="url(#paint0_linear_1089_1233)"
            />
            <path
              d="M19.8244 4.44336C19.4141 4.44336 19.0919 4.76558 19.0919 5.17578C19.0919 6.39165 18.1105 7.37305 16.8947 7.37305H9.93666C9.966 7.47559 10.0099 7.59282 10.0539 7.69526C9.43861 7.97358 8.83802 8.36914 8.29608 8.83789H16.8947C18.9161 8.83789 20.5568 7.19727 20.5568 5.17578C20.5568 4.76558 20.2346 4.44336 19.8244 4.44336ZM12.6711 16.1821C11.8607 15.9876 11.7677 15.9104 11.7677 15.4297C11.7677 14.7931 12.7026 14.4791 13.0859 14.9905C13.3284 15.3146 13.789 15.3789 14.1116 15.1364C14.4349 14.8939 14.5 14.4348 14.2575 14.1107C13.9971 13.7636 13.634 13.5183 13.2326 13.3746V12.5C13.2326 12.0952 12.905 11.7676 12.5001 11.7676C12.0953 11.7676 11.7677 12.0952 11.7677 12.5V13.3673C10.917 13.6707 10.3029 14.4761 10.3029 15.4297C10.3029 17.1198 11.571 17.4245 12.3292 17.6069C13.1396 17.8015 13.2326 17.8787 13.2326 18.3594C13.2326 18.7635 12.9042 19.0918 12.5001 19.0918C12.2677 19.0918 12.0538 18.9852 11.9143 18.7985C11.6719 18.4752 11.2148 18.4109 10.8887 18.6526C10.5654 18.8951 10.5003 19.3543 10.7428 19.6783C11.0032 20.0255 11.3663 20.2708 11.7677 20.4145V21.2891C11.7677 21.6939 12.0953 22.0215 12.5001 22.0215C12.905 22.0215 13.2326 21.6939 13.2326 21.2891V20.4217C14.0832 20.1184 14.6974 19.313 14.6974 18.3594C14.6974 16.6692 13.4292 16.3646 12.6711 16.1821Z"
              fill="url(#paint1_linear_1089_1233)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1089_1233"
                x1="12.5"
                y1="25"
                x2="12.5"
                y2="0"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00B59C" />
                <stop offset="1" stop-color="#9CFFAC" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1089_1233"
                x1="14.4264"
                y1="22.0215"
                x2="14.4264"
                y2="4.44336"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#C3FFE8" />
                <stop offset="0.9973" stop-color="#F0FFF4" />
              </linearGradient>
            </defs>
          </svg>

          <span>{{ translatesGet("TOTAL_WITHDRAWN") }} </span>
        </div>
        <div class="countMatrix flexInline">
          <span class="count">{{
            totalRstWithdrawn > 0 ? totalRstWithdrawn.toFixed(2) : "0.00"
          }}</span
          >RST
        </div>
        <div class="flexInlineBetween details">
          <span class="count"
            >{{
              rstToUsdValue(totalRstWithdrawn) > 0
                ? rstToUsdValue(totalRstWithdrawn)
                : "0.00"
            }}
            $</span
          >
        </div>
      </div>
    </div>

    <div class="darkBgBlock reffLink">
      <div class="titleBlockReffLink flexInline aboutMatrixAffilateProgram">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.7863 18.5001C19.7863 20.9727 16.457 21.5908 12.5579 21.5908C8.63748 21.5908 5.32958 20.9502 5.32958 18.4776C5.32958 16.004 8.65885 15.3859 12.5579 15.3859C16.4784 15.3859 19.7863 16.0265 19.7863 18.5001Z"
            fill="url(#paint0_linear_1188:1810)"
          />
          <path
            d="M12.558 13.0302C9.9033 13.0302 7.77469 10.8892 7.77469 8.21911C7.77469 5.548 9.9033 3.40906 12.558 3.40906C15.2126 3.40906 17.3412 5.548 17.3412 8.21911C17.3412 10.8892 15.2126 13.0302 12.558 13.0302"
            fill="url(#paint1_linear_1188:1810)"
          />
          <path
            d="M5.73064 8.30599C5.73064 9.66714 6.13662 10.9351 6.84887 11.9893C6.92213 12.0977 6.85701 12.2441 6.72779 12.2666C6.54973 12.2973 6.36556 12.3147 6.17834 12.3198C4.31122 12.369 2.6354 11.1603 2.17243 9.34067C1.48664 6.63782 3.50027 4.2113 6.06438 4.2113C6.34317 4.2113 6.60976 4.24098 6.86922 4.2942C6.90484 4.30239 6.94248 4.31876 6.96283 4.35049C6.98725 4.38938 6.96894 4.44157 6.94452 4.47535C6.17427 5.56119 5.73064 6.88549 5.73064 8.30599"
            fill="url(#paint2_linear_1188:1810)"
          />
          <path
            d="M0.216822 15.7007C0.558702 14.969 1.3839 14.4655 2.63848 14.2188C3.23066 14.0735 4.83323 13.8688 6.32387 13.8965C6.34625 13.8995 6.35846 13.9159 6.3605 13.9261C6.36355 13.9405 6.35745 13.964 6.32794 13.9793C5.63909 14.3222 2.97629 15.8133 3.31105 18.9583C3.32529 19.0944 3.21642 19.2121 3.08109 19.1916C2.42582 19.0974 0.739818 18.7331 0.216822 17.5981C-0.0721489 16.9984 -0.0721489 16.3015 0.216822 15.7007"
            fill="url(#paint3_linear_1188:1810)"
          />
          <path
            d="M18.8215 12.3196C18.6343 12.3145 18.4511 12.2982 18.272 12.2664C18.1428 12.2439 18.0777 12.0976 18.151 11.9891C18.8632 10.935 19.2692 9.66695 19.2692 8.30581C19.2692 6.88531 18.8256 5.56101 18.0553 4.47516C18.0309 4.44139 18.0136 4.38919 18.037 4.35031C18.0573 4.3196 18.096 4.3022 18.1306 4.29402C18.3911 4.2408 18.6577 4.21112 18.9365 4.21112C21.5006 4.21112 23.5142 6.63764 22.8274 9.34048C22.3644 11.1601 20.6886 12.3688 18.8215 12.3196"
            fill="url(#paint4_linear_1188:1810)"
          />
          <path
            d="M18.6714 13.9797C18.6429 13.9633 18.6368 13.9408 18.6398 13.9255C18.6419 13.9163 18.6531 13.8999 18.6755 13.8968C20.1671 13.8692 21.7687 14.0739 22.3619 14.2182C23.6165 14.4658 24.4406 14.9694 24.7825 15.7011C25.0725 16.3018 25.0725 16.9978 24.7825 17.5985C24.2595 18.7335 22.5735 19.0978 21.9182 19.192C21.7829 19.2114 21.6751 19.0947 21.6893 18.9576C22.0241 15.8137 19.3613 14.3226 18.6714 13.9797"
            fill="url(#paint5_linear_1188:1810)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1188:1810"
              x1="12.5579"
              y1="15.3859"
              x2="12.5579"
              y2="21.5908"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#06B9FF" />
              <stop offset="1" stop-color="#4F61FF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1188:1810"
              x1="12.558"
              y1="3.40906"
              x2="12.558"
              y2="13.0302"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#06B9FF" />
              <stop offset="1" stop-color="#4F61FF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1188:1810"
              x1="4.50567"
              y1="4.2113"
              x2="4.50567"
              y2="12.3213"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFDA00" />
              <stop offset="1" stop-color="#FD5E00" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_1188:1810"
              x1="3.18065"
              y1="13.8939"
              x2="3.18065"
              y2="19.1939"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFDA00" />
              <stop offset="1" stop-color="#FD5E00" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_1188:1810"
              x1="20.4946"
              y1="4.21112"
              x2="20.4946"
              y2="12.3211"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8CF8AB" />
              <stop offset="1" stop-color="#32CDA1" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_1188:1810"
              x1="21.8196"
              y1="13.8943"
              x2="21.8196"
              y2="19.1941"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8CF8AB" />
              <stop offset="1" stop-color="#32CDA1" />
            </linearGradient>
          </defs>
        </svg>

        <span style="margin-top: 7px">{{ translatesGet("REF_PAYOUTS") }}</span>
        <div class="aboutMatrix">
          <div class="flexInlineBetween"></div>
          <div class="countMatrix flexInline"><span class="count">{{userRefStat.referralReward ? userRefStat.referralReward.toFixed(2) : "0.00"}}</span>RST</div>
        </div>
        <div class="flexInlineBetween details">
          <span class="count"
            >{{
             userRefStat.referralReward && rstToUsdValue(userRefStat.referralReward) > 0
                ? rstToUsdValue(userRefStat.referralReward)
                : "0.00"
            }}
            $</span
          >
        </div>
      </div>
      <div class="lineLightGrey h"></div>
      <template v-if="userDataInRelax && userDataInRelax.userId > 0">
        <div class="flexInlineBetween">
          <div class="titleBlockReffLink">{{ translatesGet("AFFILIATE_LINK") }}</div>

          <div class="flexInline">
            <span class="reffLinkCount">{{userRefStat.referralsNumber ? userRefStat.referralsNumber : "0"}}</span>
            <span class="people"></span>
          </div>
        </div>
        <div class="titleBlockReffLink"></div>
        <div class="blockReffLink">
          <div class="linkCopy blockBorder flexInlineBetween">
            <div v-if="setReferralLink">{{ setReferralLink }}</div>
            <span
              class="copy"
              v-clipboard="value"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyFailed"
            ></span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="titleBlockReffLink"></div>
        <div class="refferalInfoToStart">
          {{ translatesGet("IS_NOT_USER_EXISTS") }}
           <a href="https://infinityincome.io" target="_blank">Infinity Income</a>.
        </div>
        <button @click="registerInRelax()" class="btnStartReff ">{{ translatesGet("START") }}</button>
        <div class="lineLightGrey h"></div>
      </template>

      <div class="titleBlockReffLink">
        <span>{{ translatesGet("ADDRESS") }}</span>
      </div>
      <div v-if="currentAddress && currentAddress !== '0x0000000000000000000000000000000000000000'" class="blockReffLink">
        <div class="linkCopy blockBorder flexInlineBetween">
            <div> {{ currentAddress }}</div>
            <span
              class="copy"
              v-clipboard="currentAddress"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyFailed"
            ></span>
        </div>
      </div>
      <div v-else>
         <button @click="$emit('showWalletOptions')" class="btnStartReff ">{{ translatesGet("SELECT_WALLET") }}</button>
      </div>
    </div>

    <div class="darkBgBlock botLinks">
      <div class="listLinksBot">
        <a
          href="https://t.me/AutoStart_Infinity_Bot"
          target="_blank"
          class="titleBot flexInlineBetween"
        >
          <span>AUTOSTART_BOT</span>
          <!-- <span>{{ translatesGet("AUTOSTART_BOT") }}</span> -->
          <div>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6733 24.0685L16.333 29.1666C16.833 29.1666 17.054 28.9379 17.333 28.6666L19.7353 
            26.3307L24.733 30.0231C25.6528 30.5306 26.3166 30.2678 26.5457 29.1692L29.8265 13.7087C30.1624 
            12.362 29.3132 11.7512 28.4333 12.1506L9.1683 19.5791C7.85329 20.1066 7.86095 20.8402 8.9286 
            21.1671L13.8724 22.7102L25.3179 15.4894C25.8583 15.1617 26.3542 15.3379 25.9471 15.6991L16.673 
            24.0683L16.6733 24.0685Z"
                fill="#CEF2EF"
              />
            </svg>
          </div>
        </a>
        <div class="lineLightGrey h"></div>
        <a
          href="https://t.me/Informer_Infinity_Bot"
          target="_blank"
          class="titleBot flexInlineBetween"
        >
          <span>INFORMER_BOT</span>
          <!-- <span>{{ translatesGet("INFORMER_BOT") }}</span> -->
          <div>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6733 24.0685L16.333 29.1666C16.833 29.1666 17.054 28.9379 17.333 28.6666L19.7353 
            26.3307L24.733 30.0231C25.6528 30.5306 26.3166 30.2678 26.5457 29.1692L29.8265 13.7087C30.1624 
            12.362 29.3132 11.7512 28.4333 12.1506L9.1683 19.5791C7.85329 20.1066 7.86095 20.8402 8.9286 
            21.1671L13.8724 22.7102L25.3179 15.4894C25.8583 15.1617 26.3542 15.3379 25.9471 15.6991L16.673 
            24.0683L16.6733 24.0685Z"
                fill="#CEF2EF"
              />
            </svg>
          </div>
        </a>

        <div class="lineLightGrey h"></div>
        <a
          href="https://t.me/InfIncSup_bot"
          target="_blank"
          class="titleBot flexInlineBetween"
        >
          <span>SUPPORT_BOT</span>
          <!-- <span>{{ translatesGet("SUPPORT_BOT") }}</span> -->
          <div>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6733 24.0685L16.333 29.1666C16.833 29.1666 17.054 28.9379 17.333 28.6666L19.7353 
              26.3307L24.733 30.0231C25.6528 30.5306 26.3166 30.2678 26.5457 29.1692L29.8265 13.7087C30.1624 
              12.362 29.3132 11.7512 28.4333 12.1506L9.1683 19.5791C7.85329 20.1066 7.86095 20.8402 8.9286 
              21.1671L13.8724 22.7102L25.3179 15.4894C25.8583 15.1617 26.3542 15.3379 25.9471 15.6991L16.673 
              24.0683L16.6733 24.0685Z"
                fill="#CEF2EF"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>
  </aside>
</template>

<script>
import MultiLang from "../core/multilang";
import { mapState } from "vuex";
import Config from "../../conf.json";
import copiedMixin from "../mixins/copiedMixin";
import Ref from "@/core/ref";
export default {
  name: "Sidebar",
  mixins: [copiedMixin],
  data() {
    return {
      value: `${Config.REF_BASE}?ref=`,
      lang: new MultiLang(this),
    };
  },
  props: {
    openSide: {
      required: false,
      default: false,
    },
  },

  mounted() {
    this.lang.init();
  },
  computed: {
    ...mapState(["rstTokensData", "rstPrice", "rstPriceFromContract", "userDataInRelax", "userRefStat", "currentAddress"]),
    rstTokensAvailable() {
      return Number(this.rstTokensData.totalRstAvailable).toFixed(2);
    },
    rstTokensLocked() {
      return Number(
        this.rstTokensData.totalRstLocked - this.rstTokensData.totalRstWithdrawn - this.rstTokensAvailable
      ).toFixed(2);
    },
    rstTotalAmount() {
      return Number(
        Number(this.rstTokensLocked) +
          Number(this.rstTokensData.totalRstWithdrawn) +
          Number(this.rstTokensData.totalRstAvailable)
      ).toFixed(2); //todo calc available tokens
    },
    rstTotalAmountWithRef() {
      return Number(
        Number(this.rstTotalAmount) +
          this.userRefStat.referralReward
      ).toFixed(2); //todo calc available tokens
    },
    totalRstWithdrawn() {
      return Number(this.rstTokensData.totalRstWithdrawn);
    },


    setReferralLink() {
      this.value += localStorage.getItem("address");
      return this.value;
    },

    setContractAddress() {
      return this.currentAddress
    },
    getCurrentLang() {
      return this.$store.state.lang;
    },
  },
  methods: {
    translatesGet(key) {
      try {
        const translations = JSON.parse(window.localStorage.getItem("translations"));
        const res = translations.lang.find((el) => el.hasOwnProperty(`${key}`));
        return res[key];
      } catch (ex) {
        // console.log(ex);
        return this.lang.get(key);
      }
    },
    rstToUsdValue(rstAmount) {
      return Number(Number(rstAmount) * this.rstPriceFromContract).toFixed(2); // TODO add real rst to usd rate
    },
    async claimTokens() {
      // const res = await this.$root.core.claimTokens();
      const res = await this.$root.core.claimMonthlyTokens();


      const result = await res.wait();
      if (result) {
        setTimeout(()=> {
          this.$emit("updatePurchasedPlansPagination")
        }, 6000)
        this.$store.commit("push_notification", {
          type: "success",
          typeClass: "success",
          message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction was processed' : 'Ваша транзакция была проведена'}`
        });
        
      }
    },
    async registerInRelax(){

      try {
        const refInLink = Ref.getReferrerAddress();

        let referrerToRegister = "0x0000000000000000000000000000000000000000"
        if(!refInLink && this.userDataInRelax.referrer.toLowerCase() !== "0x0000000000000000000000000000000000000000"){
          referrerToRegister = this.userDataInRelax.referrer.toLowerCase();
        }else if(refInLink) {
          const refIsValid = await this.$root.core.isUserExists(refInLink)
          if(refIsValid)[
            referrerToRegister = refInLink
          ]
        }

        const rawRes = await this.$root.core.registerInRelax(referrerToRegister)
        const res = await rawRes.wait();
        if(res){
          this.$store.commit("push_notification", {
            type: "success",
            typeClass: "success",
            message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction was processed' : 'Ваша транзакция была проведена'}`
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  watch: {
    getCurrentLang: function () {
      this.$forceUpdate();
    },
  },
};
</script>
