<template>
  <button class="chLang select flexInline">
    <div class="icon" :class="setFlagImg"></div>
    
    <ul>  
      <li :class="{active: setActiveLang('en')}" @click="setLang('en', $event)">
        <span class="icon enIcon"></span>
        <span>{{ translatesGet("ENGLISH") }}</span>
      </li>
<!-- 
      <li :class="{active: setActiveLang('cn')}" @click="setLang('cn', $event)">
        <span class="icon zhIcon"></span>
        <span>{{ translatesGet("CHINESE") }}</span>
      </li> -->

      <li :class="{active: setActiveLang('ru')}" @click="setLang('ru', $event)">
        <span class="icon ruIcon"></span>
        <span>{{ translatesGet("RUSSIAN") }}</span>
      </li>
<!-- 
      <li :class="{active: setActiveLang('es')}" @click="setLang('es', $event)">
        <span class="icon esIcon"></span>
        <span>{{ translatesGet("SPANISH") }}</span>
      </li>

      <li :class="{active: setActiveLang('de')}" @click="setLang('de', $event)">
        <span class="icon deIcon"></span>
        <span>{{ translatesGet("GERMAN") }}</span>
      </li>

      <li :class="{active: setActiveLang('ir')}" @click="setLang('ir', $event)">
        <span class="icon irIcon"></span>
        <span>{{ translatesGet("IRANIAN") }}</span>
      </li> -->
    </ul>
    <span v-if="this.$route && this.$route.name === 'Home'">{{translatesGet(`${getLang()}`)}}</span>
  </button>
</template>

<script>
import MultiLang from "@/core/multilang";
export default {
  data: function () {
    return {
      lang: new MultiLang(this),
      currentLang: ""
    };
  },

  mounted() {
    this.currentLang = window.localStorage.getItem("lang")
  },
  computed: {
    setFlagImg() {
      
      return this.currentLang ? this.currentLang+`Icon` : "enIcon";
    }
  },
  methods: {
    translatesGet(key) {
      try {
        const translations = JSON.parse(window.localStorage.getItem('translations'))
        const res = translations.lang.find(el => el.hasOwnProperty(`${key}`));
        return res[key]
        
      } catch (ex) {
        // console.log(ex);
        return this.lang.get(key)
      }
    },
    setLang(lang, event) {
      // console.log('setting new language');
      this.$store.dispatch("updateLanguage", lang);
      this.currentLang = lang
      // this.$root.core.setLangForAddress(lang);

    },
    setActiveLang(inputLang) {
      return inputLang === localStorage.getItem('lang');
    },
    getLang() {
      return localStorage.getItem('lang');
    }
  },
};
</script>
