import axios from 'axios';
import {ethers} from 'ethers';

import {abi} from '../abi/contractAbi'
import {tokenAbi} from '../abi/tokenAbi'
import {liquidityAbi} from '../abi/liquidityAbi'
import {relaxAbi} from '../abi/relaxAbi'
import Config from "../../conf.json";




export default class Core {

  constructor(vueContext, nodeSettings = "") {
    this.context = vueContext;
    this.contractAddress = Config.CONTRACT_ADDRESS;
    this.baseURL = Config.BASE_URL;
    this.BUSD_TOKEN_ADDRESS = Config.BUSD_TOKEN_ADDRESS
    this.RST_TOKEN_ADDRESS = Config.RST_TOKEN_ADDRESS
    this.DEFAULT_GAS_PRICE_GWEI = Config.DEFAULT_GAS_PRICE_GWEI;
    this.TOKEN_CONTRACT_ADDRESS = Config.BUSD_TOKEN_ADDRESS;
    this.liquidityAddress = Config.LIQUIDITY_ADDRESS;
    this.relaxInfinityAddress = Config.RELAX_INFINTY_ADDRESS;

    this.init();
  }

    async init() {

      try {
        if(!this.contract){
          if((window.localStorage.getItem("selectedWallet") === "metamask" || window.localStorage.getItem("selectedWallet") === "trustwallet") && window.ethereum && window.localStorage.getItem("address")){
              if(window.ethereum.chainId === "0x38" || window.ethereum.chainId === 56) { //"0x38 || 56" mainnet
                  let provider =  new ethers.providers.Web3Provider(window.ethereum);
                  this.providerAddress = provider;
                  this.signer = provider.getSigner();
                  this.contract = new ethers.Contract(this.contractAddress,  abi, provider).connect(this.signer);
                  this.BUSDContract = new ethers.Contract(this.BUSD_TOKEN_ADDRESS,  tokenAbi, provider).connect(this.signer)
                  this.RSTContract = new ethers.Contract(this.RST_TOKEN_ADDRESS,  tokenAbi, provider).connect(this.signer)
                  this.liquidityContract = new ethers.Contract(this.liquidityAddress, liquidityAbi, provider).connect(this.signer)
                  this.relaxContract = new ethers.Contract(this.relaxInfinityAddress, relaxAbi, provider).connect(this.signer)
                  return this.contract;
              }else {
                  throw new Error("Please change the network in Metamask to mainnet")
              }
             
          }else if (window.localStorage.getItem("selectedWallet") === "binance" && window.BinanceChain && window.localStorage.getItem("address")) {
              if(window.BinanceChain.chainId === "0x38" || window.BinanceChain.chainId === 56) {// "0x38 || 56" maiinet
                  let provider =  new ethers.providers.Web3Provider(window.BinanceChain);
                  this.providerAddress = provider;
                  this.signer = provider.getSigner();
                  this.contract = new ethers.Contract(this.contractAddress,  abi, provider).connect(this.signer);
                  this.BUSDContract = new ethers.Contract(this.BUSD_TOKEN_ADDRESS,  tokenAbi, provider).connect(this.signer)
                  this.RSTContract = new ethers.Contract(this.RST_TOKEN_ADDRESS,  tokenAbi, provider).connect(this.signer)
                  this.liquidityContract = new ethers.Contract(this.liquidityAddress, liquidityAbi, provider).connect(this.signer)
                  this.relaxContract = new ethers.Contract(this.relaxInfinityAddress, relaxAbi, provider).connect(this.signer)
                  return this.contract;

              }else {
                  throw new Error("Please change the network in Binance Chain Wallet to mainnet")
              }
              
          }else {
              let provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");
              this.providerAddress = provider;
              // this.signer = this.providerAddress.getSigner();
              this.contract = new ethers.Contract(this.contractAddress,  abi, provider).connect(Config.DEFAULT_UI_ADDRESS);
              this.BUSDContract = new ethers.Contract(this.BUSD_TOKEN_ADDRESS,  tokenAbi, provider).connect(Config.DEFAULT_UI_ADDRESS)
              this.RSTContract = new ethers.Contract(this.RST_TOKEN_ADDRESS,  tokenAbi, provider).connect(Config.DEFAULT_UI_ADDRESS)
              this.liquidityContract = new ethers.Contract(this.liquidityAddress, liquidityAbi, provider).connect(Config.DEFAULT_UI_ADDRESS)
              this.relaxContract = new ethers.Contract(this.relaxInfinityAddress, relaxAbi, provider).connect(Config.DEFAULT_UI_ADDRESS)
              return this.contract;
              // this.stakeContract = new ethers.Contract(this.STAKING_CONTRACT_ADDRESS,  stakingAbi, provider).connect(Config.DEFAULT_UI_ADDRESS);
          }
          
      }

      return this.contract;
      } catch (error) {
        console.log(error);
      }
        
  }

  fromHex(hexNumber){
    return parseInt(hexNumber, 16);
  }

                                                                    /***************************************  Actions methods  **************************************/ 

  async buyPlan(planIdx, amount, ref){
    try {

        const userAddress = window.localStorage.getItem("address")
        if(!userAddress){
          this.context.$store.commit("push_notification", {
            type: "warning",
            typeClass: "warning",
            message: `${window.localStorage.getItem('lang') === "en" ? 'Oops, looks like you are not connected to this website.' : 'Подключите Ваш кошелек чтобы совершить транзакцию.' }`,
          });
          return
        }

        let allowance = ethers.utils.formatUnits(
          await this.BUSDContract.allowance(userAddress, this.contractAddress), 18
          )

        if(Number(allowance) < Number(amount)){
    
            let maxAllowance = ethers.BigNumber.from(2).pow(256).sub(1);
            const gasPrice = ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei");
            let rawResult = await this.BUSDContract.approve(this.contractAddress, maxAllowance,{maxFeePerGas: gasPrice})
            debugger
            this.context.$store.commit("push_notification", {
              type: "warning",
              typeClass: "warning",
              message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction has been submitted to Blockchain' : 'Транзакция создана, ожидайте подтверждения...'}`,
            });
            let result = await rawResult.wait();
    
            if(result.transactionHash){
              this.context.$store.commit("push_notification", {
                type: "success",
                typeClass: "success",
                message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction was processed' : 'Ваша транзакция была проведена'}`,
              });
            }
        }
        amount = ethers.utils.parseUnits(`${amount}`)
        const res = await this.contract.buy(planIdx, amount, ref,{ maxFeePerGas: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})
        this.context.$store.commit("push_notification", {
          type: "warning",
          typeClass: "warning",
          message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction has been submitted to Blockchain' : 'Транзакция создана, ожидайте подтверждения...'}`,
        });
        return res
    } catch (error) {
      console.log(error);
      this.context.$store.commit('push_notification', {
        type: 'error',
        typeClass: 'error',
        message: `${(error.data ? (error.data.message ? error.data.message : error.message) : (error.message ? error.message : error.error))}`
      })
    }
  }


  async claimTokens(){
    try {
        
        const res = await this.contract.claimTokens({ maxFeePerGas: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})
        this.context.$store.commit("push_notification", {
          type: "warning",
          typeClass: "warning",
          message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction has been submitted to Blockchain' : 'Транзакция создана, ожидайте подтверждения...'}`,
        });
        return res
    } catch (error) {
      this.context.$store.commit('push_notification', {
        type: 'error',
        typeClass: 'error',
        message: `${(error.data ? (error.data.message ? error.data.message : error.message) : (error.message ? error.message : error.error))}`
      })
    }
  }
  async claimMonthlyTokens(){
    try {
        const res = await this.contract.claimTokens({ maxFeePerGas: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})
        this.context.$store.commit("push_notification", {
          type: "warning",
          typeClass: "warning",
          message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction has been submitted to Blockchain' : 'Транзакция создана, ожидайте подтверждения...'}`,
        });
        return res
    } catch (error) {
      this.context.$store.commit('push_notification', {
        type: 'error',
        typeClass: 'error',
        message: `${(error.data ? (error.data.message ? error.data.message : error.message) : (error.message ? error.message : error.error))}`
      })
    }
  }


  async setStakeAPY(APY){
    try {
        const res = await this.contract.changeStakeType(APY,{ maxFeePerGas: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})
        this.context.$store.commit("push_notification", {
          type: "warning",
          typeClass: "warning",
          message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction has been submitted to Blockchain' : 'Транзакция создана, ожидайте подтверждения...'}`,
        });
        return res
    } catch (error) {
      this.context.$store.commit('push_notification', {
        type: 'error',
        typeClass: 'error',
        message: `${(error.data ? (error.data.message ? error.data.message : error.message) : (error.message ? error.message : error.error))}`
      })
    }
  }

  async claimReward(){
    try {
      const res = await this.stakeContract.claim({ maxFeePerGas: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})
      this.context.$store.commit("push_notification", {
        type: "warning",
        typeClass: "warning",
        message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction has been submitted to Blockchain' : 'Транзакция создана, ожидайте подтверждения...'}`,
      });

      return res
    } catch (error) {
      this.context.$store.commit('push_notification', {
        type: 'error',
        typeClass: 'error',
        message: `${(error.data ? (error.data.message ? error.data.message : error.message) : (error.message ? error.message : error.error))}`
      })
    }
  }
  async unstake(){
    try {
      const res = await this.stakeContract.unstake({ maxFeePerGas: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})
      this.context.$store.commit("push_notification", {
        type: "warning",
        typeClass: "warning",
        message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction has been submitted to Blockchain' : 'Транзакция создана, ожидайте подтверждения...'}`,
      });
      return res
    } catch (error) {
      this.context.$store.commit('push_notification', {
        type: 'error',
        typeClass: 'error',
        message: `${(error.data ? (error.data.message ? error.data.message : error.message) : (error.message ? error.message : error.error))}`
      })
    }
  }

  async claimPendingPayment(){
    try {
      const res = await this.contract.claimPendingPayment({ maxFeePerGas: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})
      this.context.$store.commit("push_notification", {
        type: "warning",
        typeClass: "warning",
        message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction has been submitted to Blockchain' : 'Транзакция создана, ожидайте подтверждения...'}`,
      });
      return res
    } catch (error) {
      this.context.$store.commit('push_notification', {
        type: 'error',
        typeClass: 'error',
        message: `${(error.data ? (error.data.message ? error.data.message : error.message) : (error.message ? error.message : error.error))}`
      })
    }
  }

  async registerInRelax(refAddress){
    try {
      const userAddress = window.localStorage.getItem("address")
      if(!userAddress){
        this.context.$store.commit("push_notification", {
          type: "warning",
          typeClass: "warning",
          message: `${window.localStorage.getItem('lang') === "en" ? 'Oops, looks like you are not connected to this website.' : 'Подключите Ваш кошелек чтобы совершить транзакцию.' }`,
        });
        return
      }


      let allowance = ethers.utils.formatUnits(
        await this.BUSDContract.allowance(userAddress, this.relaxInfinityAddress), 18
        )

      if(Number(allowance) < 10 * 10**18){
  
          let maxAllowance = ethers.BigNumber.from(2).pow(256).sub(1);
          let rawResult = await this.BUSDContract.approve(this.relaxInfinityAddress, maxAllowance, { maxFeePerGas: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})
  
          this.context.$store.commit("push_notification", {
            type: "warning",
            typeClass: "warning",
            message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction has been submitted to Blockchain' : 'Транзакция создана, ожидайте подтверждения...'}`,
          });
          let result = await rawResult.wait();
  
          if(result.transactionHash){
            this.context.$store.commit("push_notification", {
              type: "success",
              typeClass: "success",
              message: "Your transaction was processed",
            });
          }
      }
      const res = await this.relaxContract.registration(refAddress, { maxFeePerGas: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})
      this.context.$store.commit("push_notification", {
        type: "warning",
        typeClass: "warning",
        message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction has been submitted to Blockchain' : 'Транзакция создана, ожидайте подтверждения...'}`,
      });
      return res
    } catch (error) {
      this.context.$store.commit('push_notification', {
        type: 'error',
        typeClass: 'error',
        message: `${(error.data ? (error.data.message ? error.data.message : error.message) : (error.message ? error.message : error.error))}`
      })
    }
  }


                                                                                    /********************************  Read methods  **************************************/ 

                                                                                    
  async getUserPurchasedPlans(userAddress) {
    const resultArray = [];
    let totalRstLocked = 0
    let totalRstWithdrawn = 0
    let totalRstAvailable = 0

    const rawRes = await this.contract.viewUserPurchases(userAddress);

    for(let plan of rawRes){
      totalRstLocked += Number(plan[1]["_hex"]) / 10 ** 18;
      totalRstWithdrawn  += Number(plan[3]["_hex"]) / 10 ** 18;
      const res = {
        boughtTokens: Number(plan[1]["_hex"]) / 10 ** 18,
        isClaimed: plan[7],
        monthlyRewardsClaimed: plan[6],
        monthlyRewardsReceived: plan[5],
        planIdx: plan[0],
        buyPriceRaw: Number(plan[2]["_hex"]) / 10 ** 18,
        time: Number(plan[4]["_hex"]),
        tokensReceived: Number(plan[3]["_hex"]) / 10 ** 18,
        
      }
      const possibleEarnings = this.calcUserPossibleEarnings(res)
      totalRstAvailable += possibleEarnings
      res.possibleEarnings = possibleEarnings.toFixed(2)
      resultArray.push(res)
    }
    resultArray.sort((a,b) => {
      return b.time - a.time
    })
    return {
      resultArray,
      totalRstLocked,
      totalRstWithdrawn,
      totalRstAvailable
    }
  }

  async getPlansDetails(){
    const resultArray = []
    const rawRes = await this.contract.viewAllPlans();
    console.log(rawRes);
    for(let plan of rawRes){
      const res = {
        minAmount: Math.round(Number(plan[0]) / 10 ** 18),
        maxAmount: Math.round(Number(plan[1]) / 10 ** 18),
        lockDuration: Number(plan[2]) / 3600,//todo add * 24
        discountPromille: (Number.parseFloat(plan[3]) / 10),
        monthlyPercent: Number(plan[4]),
        referralPromille: Number.parseFloat(plan[5]) / 10
      }
      resultArray.push(res)
    }
    return [
      {
      id: 0,
      minAmount: resultArray[0].minAmount,
      maxAmount: resultArray[0].maxAmount,
      lockDuration: {
        180: {
            discountPromille: resultArray[0].discountPromille,
            monthlyPercent: resultArray[0].monthlyPercent,
            referralPromille: resultArray[0].referralPromille
        },
        360: {
            discountPromille: resultArray[1].discountPromille,
            monthlyPercent: resultArray[1].monthlyPercent,
            referralPromille: resultArray[1].referralPromille
        }
            }
    },
      {
      id: 1,
      minAmount: resultArray[2].minAmount,
      maxAmount: resultArray[2].maxAmount,
      lockDuration: {
        180: {
            discountPromille: resultArray[2].discountPromille,
            monthlyPercent: resultArray[2].monthlyPercent,
            referralPromille: resultArray[2].referralPromille
        },
        360: {
            discountPromille: resultArray[3].discountPromille,
            monthlyPercent: resultArray[3].monthlyPercent,
            referralPromille: resultArray[3].referralPromille
        }
            }
    },
      {
      id: 2,
      minAmount: resultArray[4].minAmount,
      maxAmount: resultArray[4].maxAmount,
      lockDuration: {
        180: {
            discountPromille: resultArray[4].discountPromille,
            monthlyPercent: resultArray[4].monthlyPercent,
            referralPromille: resultArray[4].referralPromille
        },
        360: {
            discountPromille: resultArray[5].discountPromille,
            monthlyPercent: resultArray[5].monthlyPercent,
            referralPromille: resultArray[5].referralPromille
        }
            }
    },
      {
      id: 3,
      minAmount: resultArray[6].minAmount,
      maxAmount: resultArray[6].maxAmount,
      lockDuration: {
        180: {
            discountPromille: resultArray[6].discountPromille,
            monthlyPercent: resultArray[6].monthlyPercent,
            referralPromille: resultArray[6].referralPromille
        },
        360: {
            discountPromille: resultArray[7].discountPromille,
            monthlyPercent: resultArray[7].monthlyPercent,
            referralPromille: resultArray[7].referralPromille
        }
            }
    },
      {
      id: 4,
      minAmount: resultArray[8].minAmount,
      maxAmount: resultArray[8].maxAmount,
      lockDuration: {
        180: {
            discountPromille: resultArray[8].discountPromille,
            monthlyPercent: resultArray[8].monthlyPercent,
            referralPromille: resultArray[8].referralPromille
        },
        360: {
            discountPromille: resultArray[9].discountPromille,
            monthlyPercent: resultArray[9].monthlyPercent,
            referralPromille: resultArray[9].referralPromille
        }
            }
    },
      {
      id: 5,
      minAmount: resultArray[10].minAmount,
      maxAmount: resultArray[10].maxAmount,
      lockDuration: {
        360: {
            discountPromille: resultArray[10].discountPromille,
            monthlyPercent: resultArray[10].monthlyPercent,
            referralPromille: resultArray[10].referralPromille
        }
            }
    }
  ]
}

  calcUserPossibleEarnings(plan){
    let isLastMonth = false;
    let plans = this.context.$store.state.plansDetails;
    if(!plans.length){
      plans = Config.plans;
    }
   
    if(plan.tokensReceived === plan.boughtTokens){
      return 0
    }
    const indexToFind =
    plan.planIdx === 10
    ? 5
    : plan.planIdx % 2 === 0
    ? plan.planIdx / 2
    : Math.floor((plan.planIdx - 1) / 2 );
      const duration =
      plan.planIdx === 10
        ? "360"
        : plan.planIdx % 2 === 0
        ? "180"  
        : "360";
    
    const planDescription = plans.find(el => el.id === indexToFind)
    const finalTime = Math.floor(new Date().getTime() / 1000) 
    const startTime = plan.time 
    const CLAIMING_PERIOD = 3600 * 24 * 30;
    let months = (finalTime - startTime) / CLAIMING_PERIOD 
    if(months >= Number(duration) / 30){ 
      months = Number(duration) / 30 
      isLastMonth = true
    }

    if(months > plan.monthlyRewardsReceived){ 
      let rewardsNumber = Math.floor(months - plan.monthlyRewardsReceived); 
        if(rewardsNumber === 0){
          return 0
        }
        if(rewardsNumber >= (Number(duration) / 30)){
          rewardsNumber = Number(duration) / 30
        }
      
      const percent =  isLastMonth === false ? planDescription.lockDuration[duration].monthlyPercent * rewardsNumber : 100;

      const tokensAmount = plan.boughtTokens * percent / 100;

      return tokensAmount > (plan.boughtTokens - plan.tokensReceived) ? (plan.boughtTokens - plan.tokensReceived) : tokensAmount;
      
    }
  }

  async isUserExists(address) {

    let isUserExists = await this.relaxContract.isUserExists(address);

    return isUserExists;
  }
  async getUserInRelax(address) {

    let userDataRaw = await this.relaxContract.users(address);
    
    let userData = {
      userId: Number(userDataRaw.id),
      userReferrer: userDataRaw.referrer,

    }
    return userData
  }

  async getUserBusdBalance(userAddress){
    const rawRes = await this.BUSDContract.balanceOf(userAddress);
    const busdBalance = ethers.utils.formatUnits(rawRes);
    return busdBalance
  }



                                                              /***********************************************  UI refreshing methods  ********************************/

 
  startUpdateUserDataLoop(userAddress ,period = 10000 /* miliseconds */) {
    let _this = this;

    setTimeout(async function tick() {
      try {

        const purchasedPlansData = await _this.getUserPurchasedPlans(userAddress)
        const rstTokensData = {
          totalRstLocked: purchasedPlansData.totalRstLocked,
          totalRstWithdrawn: purchasedPlansData.totalRstWithdrawn,
          totalRstAvailable: purchasedPlansData.totalRstAvailable
        }

        _this.context.$store.commit('setPurchasedPlans', purchasedPlansData.resultArray)
        _this.context.$store.commit('setRstTokensData', rstTokensData)
        
        const userData = await _this.getUserInRelax(userAddress);
        // const isUserExists = userData.userId > 0 ? true : false;
        

        const userRefStats = await _this.getUserRefStats(userAddress)
       

        const userTransactions = await _this.getUserTransactions(userAddress);
        

        const userBusdBalance = await _this.getUserBusdBalance(userAddress);
        _this.context.$store.commit('setUserDataInRelax', userData)
        _this.context.$store.commit('setUserRefStats', userRefStats)
        _this.context.$store.commit('setUserTransactions', userTransactions)
        _this.context.$store.commit('setUserBusdBalance', userBusdBalance);
          
        setTimeout(tick, period);
      } catch (ex) {
        console.log(ex)
        setTimeout(tick, period);
      }
    });
  }



  startUpdateRstRateLoop(period = 360000){
    let _this = this
    setTimeout(async function tick() {
      try {
          let plans = await _this.getPlansDetails();
          
          // let reserves = await _this.getReserves()
          // const rstPrice = (Number(reserves.BNBReserves) / Number(reserves.RSTReserves))
          const rstPriceFromContract = await _this.getTokenPriceFromContract()
          // _this.context.$store.commit("setReserves", reserves);  
          // _this.context.$store.commit("setRstPrice", rstPrice);  
          _this.context.$store.commit("setPlans", plans);
          _this.context.$store.commit("setRstPriceFromContract", rstPriceFromContract);  

      setTimeout(tick, period);
      } catch (ex) {
      console.log(ex);
      setTimeout(tick, period);
      }
  }, 300);

  }

  getCurrentRate(period = 60000 /* miliseconds */) {
    return 1
}


  // moveInArray (arr, from, to){
  //   if (Object.prototype.toString.call(arr) !== '[object Array]') {
  //     throw new Error('Please provide a valid array');
  //   }
  //   var item = arr.splice(from, 1);
  //   arr.splice(to, 0, item[0]);
  // }


                                   /******************************************-------------- API CALLS ------------------************************************************/ 

  

  async getContractBalance() {
    let response = await this.tokenContract.balanceOf(Config.CONTRACT_ADDRESS);

    response = ethers.utils.formatUnits(response)

    return response;
  }
  async getTokenPriceFromContract() {
    let response = await this.contract.tokenPrice();

    response = Number(ethers.utils.formatUnits(response, 18))

    return response;
  }


  async getUserTransactions(userAddress) {
    let response = await axios.get('/getTransactionHistory', {
      baseURL: this.baseURL,
      params: {
        address: userAddress.toLowerCase()
      }
    })
    response.data.sort((a,b) => {
      if(a.block_timestamp === b.block_timestamp){
        return b.event_index - a.event_index
      }else {
        return b.block_timestamp - a.block_timestamp
      }
    })
    return response.data;
  }

   //gets reserves of trading pair
  
   async getReserves() {
    let rawResult = await this.liquidityContract.getReserves();

    let result = {
        RSTReserves: ethers.utils.formatUnits(rawResult[0]["_hex"],18),
        BNBReserves: ethers.utils.formatUnits(rawResult[1]["_hex"],18)
    }

    return result
}

  async isUserExists(userAddress){
    return await this.relaxContract.isUserExists(userAddress);
  }
  
  async getUserRefStats(userAddress){
    const rawRes = await this.contract.refStats(userAddress)
    const result = {
      referralsNumber: Number.parseInt(rawRes[0]["_hex"]),
      referralReward: Number(rawRes[1]['_hex']) / 10 ** 18
    }

    return result
  }

  getCorrectErrorText(msg) {
    if(msg.length === 22){
      const code = msg[msg.length-2]+msg[msg.length-1]
      if(Number(code) && (Number(code) >= 0 && Number(code) <= 18)){
        return contractErrors[code]
      }
    }else if (msg.length > 200){
      return "Transaction failed"
    }
    return msg
  }
  // getReadableDataFromEvent(event1, name1) {
  //   let data = event1.data;
  //   let eventName = name1;
  //   let event_index = event1.logIndex
  
  //   let block_number = event1.blockNumber;
  //   let transaction_id = event1.transactionHash;
  
    
  //   if(eventName === "MonthlyClaimReceived") {
  //     let recipient = "0x" + event1.topics[1].substr(26, 40);
  //     let purchaseIdx = Number(event1.topics[2]);
  //     let rewardsNumber = Number.parseInt(data.substr(0, 2 + 64));
  //     let tokensAmount = Number("0x" + data.substr(2 + 64, 64)) / 10 ** 18;
  //   let block_timestamp = Number.parseInt("0x" + data.substr(2+64*2, 64 ));
  
  //     return  {
  //       event_index,
  //       eventName,
  //       recipient,
  //       purchaseIdx,
  //       rewardsNumber,
  //       tokensAmount,
  //       block_number,
  //       transaction_id,
  //       block_timestamp 
  //     }
  //   }else if(eventName === "ClaimReceived") {
  //     let recipient = "0x" + event1.topics[1].substr(26, 40);
  //     let purchaseIdx = Number(event1.topics[2]);
  //     let tokensAmount = Number(data.substr(0, 2 + 64)) / 10 ** 18;
  //     let block_timestamp = Number.parseInt("0x" + data.substr(2+64, 64 ));
  
  //     return  {
  //       event_index,
  //       eventName,
  //       recipient,
  //       purchaseIdx,
  //       tokensAmount,
  //       block_number,
  //       transaction_id,
  //       block_timestamp 
  //     }
   
  // }
  // }


  // async getEvents(blockFrom) {
  //   let allEvents = [];
  //   let lastBlockReached = false
  //   try {
  //     //устанавливаем по какой блок сканировать ивенты
  //     let lastBlock = await this.providerAddress.getBlockNumber()
  
  //     //создаем фильтры для каждого ивента
  //     let filterMonthlyClaim = this.contract.filters.MonthlyClaimReceived();
  //     let filterClaim = this.contract.filters.ClaimReceived();
  
  //     for (let block = blockFrom; block < lastBlock;) {
  //       //устанавливаем начальный блок для фильтров
  //       console.log('startblock', block)
  //       filterMonthlyClaim.fromBlock = block;
  //       filterClaim.fromBlock = block;
  
  //       //устанавливаем конечный блок для фильтров
  //       filterMonthlyClaim.toBlock = block + 5000;
  //       filterClaim.toBlock = block + 5000
  
  //       //проверяем или конечный блок больше последнего смайненого блока 
  //       if (filterMonthlyClaim.toBlock > lastBlock) {
  //           filterMonthlyClaim.toBlock = lastBlock;
  //           filterClaim.toBlock = lastBlock;
  
  //         lastBlockReached = true;
  //       }
  //       //сканируем ивенты
  //       let eventsMonthlyClaim = await this.providerAddress.getLogs(filterMonthlyClaim);
  //       let eventsClaim = await this.providerAddress.getLogs(filterClaim);

  
  //       //парсим их чтобы они подходили в json schema
  //       let resultMonthlyClaim = eventsMonthlyClaim.map(el => this.getReadableDataFromEvent(el, "MonthlyClaim"))
  //       let resultClaim = eventsClaim.map(el => this.getReadableDataFromEvent(el, "ClaimReceived"))

  
  //       //возвращаем один массив со всеми ивентами
  //       allEvents = [...allEvents, ...resultMonthlyClaim, ...resultClaim]
  
  
  //       if(lastBlockReached){
  //         debugger
  //         return
  //           new Promise(async res => {
  //               try {
  //       // 	let hasScannedBlock = `SELECT blockNumber FROM scannedBlocks`;
  //       // 	let result = await connection.query(hasScannedBlock)
  
  //         // if(result && result[0][0].blockNumber) {
  //           let insertLastBlockQuery = `UPDATE scannedBlocks SET blockNumber = "${lastBlock}"`; // TODO return last block
  //           await connection.query(insertLastBlockQuery);
  //           logger.info(`Last scanned block ${lastBlock}`);
  //         // }
  //         // else if(result && !result[0][0].blockNumber) {
  //         // 	let insertLastBlockQuery = `INSERT INTO scannedBlocks (blockNumber) VALUES ("${lastBlock}")`;
  //         // 	await connection.query(insertLastBlockQuery);
  //         // 	logger.info(`Last scanned block ${lastBlock}`);
  //         // }
  //               }
  //               catch (e) {
  //                   logger.error(e);
  //               }
        
  //               res();
  //           });
  //           break;
  //       } 
        
  //       block+= 5000;
  //     }
  
  
  //     return allEvents;
  //   } catch (error) {
  //     console.log(error);
  //   }
  
  // }
}

 



