<template>
    <div v-if="plan.id !== 5" class="cardBuyToken">
                  <div class="flexInlineBetween">
                    <div>{{translatesGet("LOCK_FOR_DAYS")}}</div>
                    <div class="moreInfo">
                      <button @click="showDesc = !showDesc" class="btnMoreInfo" :class="{active: showDesc}">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.9997 6.66663V10.8333"
                            stroke="#CEF2EF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.9997 14.1667C10.4599 14.1667 10.833 13.7936 10.833 13.3333C10.833 12.8731 10.4599 12.5 9.9997 12.5C9.53947 12.5 9.16637 12.8731 9.16637 13.3333C9.16637 13.7936 9.53947 14.1667 9.9997 14.1667Z"
                            fill="#CEF2EF"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.9997 18.3333C14.6021 18.3333 18.333 14.6023 18.333 9.99996C18.333 5.39759 14.6021 1.66663 9.9997 1.66663C5.39733 1.66663 1.66637 5.39759 1.66637 9.99996C1.66637 14.6023 5.39733 18.3333 9.9997 18.3333Z"
                            stroke="#CEF2EF"
                            stroke-width="1.5"
                          />
                        </svg>
                        <div v-if="currentDuration === 180" class="cardMoreInfo">
                          
                          <div  class="cardMoreInfoTitle"> {{translatesGet("UNLOCK_TITLE")}} (%) <div class="closeImg"></div></div>
                          <div class="cardMoreInfoVisio">
                            <div class="cardMoreInfoVisioColumn">
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>30 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>60 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>90 {{translatesGet("DAYS")}}</div>
                              </div>
                            </div>
                            <div class="cardMoreInfoVisioColumn">
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>120 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>150 {{translatesGet("DAYS")}}</div>
                              </div>
                               <div v-if="plan.id < 4" class="visioBlock">
                                <div class="visioBlockTitle">{{ this.currentDuration === 180 ? "75" : "45" }}%</div>
                                <div>180 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div v-else class="visioBlock">
                                <div class="visioBlockTitle">{{ this.currentDuration === 180 ? "65" : "23" }}%</div>
                                <div>180 {{translatesGet("DAYS")}}</div>
                              </div>
                            </div>
                          </div>
                          {{translatesGet("UNLOCK_DESC")}}
                        </div>
                        <div v-else class="cardMoreInfo">
                          <div class="cardMoreInfoTitle">  {{translatesGet("UNLOCK_TITLE")}} (%)  <div class="closeImg"></div></div>
                          <div class="cardMoreInfoVisio">
                            <div class="cardMoreInfoVisioColumn">
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>30 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>60 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>90 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>120 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>150 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>180 {{translatesGet("DAYS")}}</div>
                              </div>
                            </div>
                            <div class="cardMoreInfoVisioColumn">
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>210 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>240 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>270 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>300 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">{{plan.id < 4 ? "5" : "7" }}%</div>
                                <div>330 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div v-if="plan.id < 4" class="visioBlock">
                                <div class="visioBlockTitle">{{ this.currentDuration === 180 ? "75" : "45" }}%</div>
                                <div>360 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div v-else class="visioBlock">
                                <div class="visioBlockTitle">{{ this.currentDuration === 180 ? "65" : "23" }}%</div>
                                <div>360 {{translatesGet("DAYS")}}</div>
                              </div>
                            </div>
                          </div>
                          {{translatesGet("UNLOCK_DESC")}}
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="buttonsSwitch">
                    <button @click="changeDuration(180)" class="switchBtn" :class="{active: currentDuration === 180}">180</button>
                    <button @click="changeDuration(360)" class="switchBtn" :class="{active: currentDuration === 360}">360</button>
                  </div>
                  <div class="flexInlineBetween">
                    <div>{{translatesGet("SALE")}}</div>
                    <div class="amount">1 RST = <span>{{getPlanPrice > 0 ? getPlanPrice  : "0.00"}} BUSD </span></div>
                  </div>
                  <div class="perCent">-{{plan.lockDuration ? plan.lockDuration[`${currentDuration}`].discountPromille: 0}} %</div>
                  <div v-if="plan.id < 4" class="saleAmount"> {{numberWithSpaces(plan.minAmount)}} - {{numberWithSpaces(plan.maxAmount - 1)}} BUSD</div>
                  <div v-else-if="plan.id === 4" class="saleAmount"> {{numberWithSpaces(plan.minAmount)}} - {{numberWithSpaces(plan.maxAmount)}} BUSD</div>
                  <div class="lineLightGrey h"></div>
                  <div class="flexInlineBetween refprog">
                    <div>{{translatesGet("REF_PROGRAMM")}}</div>
                    <div class="boldText">{{plan.lockDuration ? plan.lockDuration[`${currentDuration}`].referralPromille: 0}} %</div>
                  </div>
                  <div @click="setMaxAllowedDeposit()" class="flexInlineBetween">
                                        <div>{{translatesGet("AVAILABLE")}}</div>
                    <div  class="boldText boldText boldTextClick ">{{getUserBusdBalance > 0 ? getUserBusdBalance : "0.00"}} <span>BUSD</span></div>
                  </div>

                  <div class="inputAmount">
                    <input placeholder="Enter amount" type="number" v-model="inputValue"/>
                    <div class="amount">Busd</div>
                  </div>

                  <div class="flexInlineBetween">
                    <div>{{translatesGet("TO")}}</div>
                    <div class="boldText">{{isFinite(calcRstOutput) ? calcRstOutput :  "0.00"}} <span>RST</span></div>
                  </div>
                  <button @click="buyPlan()" class="btnBuyToken">{{translatesGet("BUY_BUTTON")}}</button>
    </div>
    <div v-else class="cardBuyToken cardBuyTokenLast">
                  <div class="flexInlineBetween">
                    <div>{{translatesGet("LOCK_FOR_DAYS")}}</div>
                    <div class="moreInfo">
                      <button @click="showDesc=!showDesc" class="btnMoreInfo" :class="{active: showDesc}">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.9997 6.66663V10.8333"
                            stroke="#CEF2EF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.9997 14.1667C10.4599 14.1667 10.833 13.7936 10.833 13.3333C10.833 12.8731 10.4599 12.5 9.9997 12.5C9.53947 12.5 9.16637 12.8731 9.16637 13.3333C9.16637 13.7936 9.53947 14.1667 9.9997 14.1667Z"
                            fill="#CEF2EF"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.9997 18.3333C14.6021 18.3333 18.333 14.6023 18.333 9.99996C18.333 5.39759 14.6021 1.66663 9.9997 1.66663C5.39733 1.66663 1.66637 5.39759 1.66637 9.99996C1.66637 14.6023 5.39733 18.3333 9.9997 18.3333Z"
                            stroke="#CEF2EF"
                            stroke-width="1.5"
                          />
                        </svg>
                        <div class="cardMoreInfo">
                          <div class="cardMoreInfoTitle">  {{translatesGet("LOCK_FOR_DAYS")}} (%)  <div class="closeImg"></div></div>                         
                          <div class="cardMoreInfoVisio">
                            <div class="cardMoreInfoVisioColumn">
                              <div class="visioBlock">
                                <div class="visioBlockTitle">9%</div>
                                <div>30 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">9%</div>
                                <div>60 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">9%</div>
                                <div>90 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">9%</div>
                                <div>120 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">9%</div>
                                <div>150 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">9%</div>
                                <div>180 {{translatesGet("DAYS")}}</div>
                              </div>
                            </div>
                            <div class="cardMoreInfoVisioColumn">
                              <div class="visioBlock">
                                <div class="visioBlockTitle">9%</div>
                                <div>210 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">9%</div>
                                <div>240 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">9%</div>
                                <div>270 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">9%</div>
                                <div>300 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">9%</div>
                                <div>330 {{translatesGet("DAYS")}}</div>
                              </div>
                              <div class="visioBlock">
                                <div class="visioBlockTitle">1%</div>
                                <div>360 {{translatesGet("DAYS")}}</div>
                              </div>
                            </div>
                          </div>
                            {{translatesGet("UNLOCK_DESC")}}
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="buttonsSwitch">
                    <button class="switchBtn">360</button>
                  </div>
                  <div class="flexInlineBetween">
                    <div>{{translatesGet("SALE")}}</div>
                    <div class="amount">1 RST = <span>{{getMinPlanPrice > 0 ? getMinPlanPrice : "0.00"}} BUSD </span></div>
                  </div>
                  <div class="perCent">-{{plan.lockDuration ? plan.lockDuration["360"].discountPromille : 0}} %</div>
                  <div class="saleAmount"> 100 000 BUSD</div>
                  <div class="lineLightGrey h"></div>
                  <div class="flexInlineBetween refprog">
                    <div>{{translatesGet("REF_PROGRAMM")}}</div>
                    <div class="boldText">{{plan.lockDuration ? plan.lockDuration["360"].referralPromille: 0}} %</div>
                  </div>
                  <div class="flexInlineBetween">
                    <div>{{translatesGet("FROM")}}  </div>
                    <div class="boldText">{{plan.maxAmount ? plan.maxAmount : 0}} <span> BUSD</span></div>

                  </div>

                  <!-- <div class="inputAmount">
                    <input placeholder="amount"  v-model="inputValue"/>
                    <div class="amount">Busd</div>
                  </div> -->

                  <div class="flexInlineBetween">
                    <div>{{translatesGet("TO")}}</div>
                    <div class="boldText">{{calcRstMaxOutput}} <span>RST</span></div>
                  </div>
                  <button @click="buyPlan()" class="btnBuyToken">{{translatesGet("BUY_BUTTON")}}</button>
    </div>
</template>

<script>
import Ref from "@/core/ref";
import { mapState } from 'vuex';
import MultiLang from '../core/multilang';
import Config from "../../conf.json"
export default {
    name: "PlanCard",

    props: ["plan"],
    data(){
        return{
            currentDuration: 180,
            inputValue: 0,
            lang: new MultiLang(this),
            showDesc: false
            // plans: null
        }
    },
    created(){
        this.inputValue = this.plan.minAmount ? this.plan.minAmount : 0
        Ref.setReferrerAddressIfExists(this);

        this.lang.init()
    },
    methods: {
      setMaxAllowedDeposit(){
        this.inputValue = Number(this.userBusdBalance) > Number(this.plan.maxAmount) ? this.plan.maxAmount - 1 : Math.round(this.userBusdBalance)
      },
       numberWithSpaces(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      translatesGet(key) {
      try {
        const translations = JSON.parse(window.localStorage.getItem('translations'))
        const res = translations.lang.find(el => el.hasOwnProperty(`${key}`));
        return res[key]
        
      } catch (ex) {
        console.log(ex);
        return this.lang.get(key)
      }
    },
    
        changeDuration(duration){
            this.currentDuration = duration
        },
        async buyPlan(){
            try {
              const additionIndex = this.currentDuration === 180 ? 0 : 1;
              const planIndexInContract = (this.plan.id * 2) + additionIndex;
              const amount = Number(this.inputValue);
              const refInLink = Ref.getReferrerAddress();

              let referrerToRegister = "0x0000000000000000000000000000000000000000"
              if(!refInLink && this.userDataInRelax.referrer.toLowerCase() !== "0x0000000000000000000000000000000000000000"){
                //if no referrer address provided or it is invalid we search for referrer in infinity income project
                referrerToRegister = this.userDataInRelax.referrer.toLowerCase();
              }else if(refInLink) {
                // if referrer address provided is valid, we check if it is registered in infinity income project
                const refIsValid = await this.$root.core.isUserExists(refInLink)
                if(refIsValid){

                  referrerToRegister = refInLink;
                }

                if(referrerToRegister.toLowerCase() === this.currentAddress.toLowerCase()){
                  referrerToRegister = "0x0000000000000000000000000000000000000000";
                }
              }
              //we do not want owner address to be a referrer
              if(referrerToRegister.toLowerCase() === Config.OWNER_ADDRESS.toLowerCase()){
                referrerToRegister = "0x0000000000000000000000000000000000000000"
              }
              if(amount < this.plan.minAmount){
                  this.$store.commit("push_notification", {
                  type: "warning",
                  typeClass: "warning",
                  message: `${window.localStorage.getItem('lang') === "en" ? 'Min purchase amount is: ' : 'Минимальная сумма покупки составляет: '}`+`${this.plan.minAmount} BUSD`
                  });
                  return
              }
              if(this.plan.id < 4 && amount >= this.plan.maxAmount){
                  this.$store.commit("push_notification", {
                  type: "warning",
                  typeClass: "warning",
                  message: `${window.localStorage.getItem('lang') === "en" ? 'Max purchase amount is: ' : 'Максимальная сумма покупки составляет: '}` + `${this.plan.maxAmount - 1} BUSD`
                  });
                  return;
              }else if (this.plan.id >= 4 && amount > this.plan.maxAmount) {
                this.$store.commit("push_notification", {
                  type: "warning",
                  typeClass: "warning",
                  message: `${window.localStorage.getItem('lang') === "en" ? 'Max purchase amount is: ' : 'Максимальная сумма покупки составляет: '}`+`${this.plan.maxAmount } BUSD`
                  });
                  return
              }

              const rawRes = await this.$root.core.buyPlan(planIndexInContract, amount, referrerToRegister);
              const res = await rawRes.wait()
              if(res){
                this.$store.commit("push_notification", {
                type: "success",
                typeClass: "success",
                message: `${window.localStorage.getItem('lang') === "en" ? 'Your transaction was processed' : 'Ваша транзакция была проведена'}`
              });
              }
            } catch (error) {
              console.log(error);
            }

        }
        
    },
    computed: {
      ...mapState(['rstPriceFromContract', 'userDataInRelax', "userBusdBalance", "currentAddress"]),
        calcRstOutput(){
            return (this.inputValue > 0 && this.getPlanPrice) ? Number(this.inputValue / this.getPlanPrice).toFixed(2) : 0
        },
        calcRstMaxOutput(){
          const discountPercent = (100 - this.plan.lockDuration["360"].discountPromille) / 100
            return (this.inputValue > 0 && this.rstPriceFromContract) ? Number(this.inputValue / (this.rstPriceFromContract * discountPercent)).toFixed(2) : 0
        },
        getPlanPrice(){
          const discountPercent = (100 - this.plan.lockDuration[this.currentDuration].discountPromille) / 100

          return this.rstPriceFromContract * discountPercent >= 0.1 ? (this.rstPriceFromContract * discountPercent).toFixed(3) : this.rstPriceFromContract * discountPercent >= 0.01 ? (this.rstPriceFromContract * discountPercent).toFixed(4) : (this.rstPriceFromContract * discountPercent).toFixed(6);
        },
        getMinPlanPrice(){
          const discountPercent = (100 - this.plan.lockDuration["360"].discountPromille) / 100

          return this.rstPriceFromContract * discountPercent >= 0.1 ? (this.rstPriceFromContract * discountPercent).toFixed(3) : this.rstPriceFromContract * discountPercent >= 0.01 ? (this.rstPriceFromContract * discountPercent).toFixed(4) : (this.rstPriceFromContract * discountPercent).toFixed(6);
        },
        getCurrentLang() {
          return this.$store.state.lang;
        },
        getUserBusdBalance(){
          return Number(this.userBusdBalance).toFixed(2)
        }
    },
    mounted(){
        // this.plans = Config.plans
    },
    watch: {
    getCurrentLang: function () {
      this.$forceUpdate();
    },
  },
}
</script>