export const relaxAbi = [
    {
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "isUserExists",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{"inputs":[{"internalType":"address","name":"","type":"address"}],"name":"users","outputs":[{"internalType":"uint256","name":"id","type":"uint256"},{"internalType":"address","name":"referrer","type":"address"},{"internalType":"uint256","name":"referralsCount","type":"uint256"},{"internalType":"bool","name":"autoReinvest","type":"bool"},{"internalType":"uint8","name":"stakeTypeIdx","type":"uint8"},{"internalType":"uint256","name":"tokensReceived","type":"uint256"}],"stateMutability":"view","type":"function"},
	{"inputs":[{"internalType":"address","name":"referrerAddress","type":"address"}],"name":"registration","outputs":[],"stateMutability":"nonpayable","type":"function"}
]