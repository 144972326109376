export default {
    name: 'copiedMixin',
    methods: {
        copySuccess() {
            this.$store.commit('push_notification', {
                type: "successCopy",
                typeClass: "success",
                message: "Copied!"
              })
        },
        copyFailed() {
            this.$store.commit('push_notification', {
                type: "errorCopy",
                typeClass: "error",
                message: "Error when coping"
              })
        }
    }
}