import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import {SHEET_ID, API_KEY} from '../../conf.json';

Vue.use(Vuex)
function fetchLanguages(data) {
  let headers = data[0]
  let langs = headers.slice(1).map(m => m.toLowerCase())
  let languages = {}
  for (let lang of langs) {
      languages[lang] = []
  }
  for (let i = 1; i < data.length; i++) {
      for (let j = 0; j < langs.length; j++) {
          try {
              let trnslts = data[i][j + 1].trim()
              if (trnslts.length) {
                let obj = {};
                obj[`${data[i][0]}`] =  trnslts
                  languages[langs[j]].push(obj)
              }
          } catch (ex) {
              //console.log(ex)
          }
      }
  }
  return languages;
}

export default new Vuex.Store({
  state: {
    notifications: [],
    translations: {},
    lang: "en",
    plansDetails: [],
    userPurchasedPlans: [],
    rstTokensData: {},
    userBusdBalance: 0,
    userTransactions: [],
    reserves: {},
    rstPrice: 0,
    rstPriceFromContract: 0,
    userDataInRelax: {},
    userRefStat: {},
    currentAddress: "0x0000000000000000000000000000000000000000"
  },
  mutations: {
    setLanguage(state, lang) {
      state.lang = lang;
    },
    setUserRefStats(state, data){
      state.userRefStat = data
    },
    
    setUserTransactions(state, transactions) {
      state.userTransactions = transactions;
    },
    
    setUserBusdBalance(state, data) {
      state.userBusdBalance = data;
    },


    push_notification(state, notification) {
      state.notifications.push({
        ...notification,
        id: (Math.random().toString(36) + Date.now().toString(36)).substr(2)
      })
    },
    remove_notification(state, notificationToRemove) {
      state.notifications = state.notifications.filter(notification => notification.id != notificationToRemove.id);
    },
    setCurrentAddress(state, address) {
      state.currentAddress = address;
    },
    setTranslations(state, data){
      state.translations = data;
    },
    setPlans(state, data){
      state.plansDetails = data;
    },
    setPurchasedPlans(state, data){
      state.userPurchasedPlans = data;
    },
    setRstTokensData(state, data){
      state.rstTokensData = data;
    },
    setReserves(state, data){
      state.reserves = data;
    },
    setRstPrice(state, data){
      state.rstPrice = data;
    },
    setRstPriceFromContract(state, data){
      state.rstPriceFromContract = data;
    },
    setUserDataInRelax(state, data){
      state.userDataInRelax = data;
    }
    },
    
  actions: {
    addNotification({commit}, notificaiton) {
      commit('push_notification', notificaiton)
    },
    async updateTranslations({ commit }) {
      let lang = window.localStorage.getItem("lang") || 'en'
      let translation = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/buyTokensCheap!A1%3AL200?key=${API_KEY}`
      )
      if (translation && translation.status == 200) {
          let parsedTranslation = fetchLanguages(translation.data.values)
          commit("setTranslations", parsedTranslation)
          if(parsedTranslation && parsedTranslation.hasOwnProperty('en')){
            window.localStorage.setItem("translations", JSON.stringify({lang: parsedTranslation[`${lang}`]}))
          }
      }else {
        console.error('error fetching translations')
      }

    },
    updateLanguage({ commit }, lang) {
      commit('setLanguage', lang);
      localStorage.setItem("lang", lang);
      let translation = this.state.translations;
      if(translation.hasOwnProperty('en')){

        window.localStorage.removeItem("translations")
        window.localStorage.setItem("translations", JSON.stringify({lang: translation[`${lang}`]}))

      }
    },
  },
  modules: {
  }
})
